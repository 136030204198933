import React from "react";

const StatisticItem = ({ title, coloredText }) => (
  <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
      {title}
    </dt>
    <dd className="order-1 text-5xl font-extrabold text-teal-600">
      {coloredText}
    </dd>
  </div>
);

export default StatisticItem;
