import React from "react";

const PriceSection = ({
  title,
  price,
  currency,
  linkText,
  linkPath,
  buttonText,
  buttonPath,
}) => (
  <div className="bg-gray-100 py-12">
    <div className="bg-white">
      <div className="relative bg-gray-100 mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-8 px-6 text-center max-w-xl mx-auto rounded-lg shadow-lg overflow-hidden bg-white lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
          <p className="text-lg leading-6 font-medium text-gray-900">{title}</p>
          <div className="mt-4 flex items-center justify-center text-5xl font-extrabold text-gray-900">
            <span>{price}</span>
            <span className="ml-3 text-xl font-medium text-gray-500">
              {currency}
            </span>
          </div>
          {linkText && linkPath && (
            <p className="mt-4 text-sm">
              <a
                href={linkPath}
                className="font-medium text-gray-500 underline"
              >
                {linkText}
              </a>
            </p>
          )}

          {buttonText && buttonPath && (
            <div className="mt-6">
              <div className="rounded-md shadow">
                <a
                  href={buttonPath}
                  className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900"
                >
                  {buttonText}
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default PriceSection;
