import React from "react";
import {
  NewspaperIcon,
  PhoneIcon,
  SupportIcon,
  ScaleIcon,
  DocumentSearchIcon,
  CodeIcon,
  ClipboardCheckIcon
} from "@heroicons/react/outline";

import PageTitle from "../components/elements/PageTitle";
import AboutUsSection from "../components/sections/AboutUsSection";
import ServiceSection from "../components/sections/ServiceSection";
import ServiceItem from "../components/elements/ServiceItem";
import StatisticSection from "../components/sections/StatisticSection";
import PortfolioSection from "../components/sections/PortfolioSection";
import ContactSection from "../components/sections/ContactSection";
import Footer from "../components/sections/Footer";
import StatisticItem from "../components/elements/StatisticItem";
import PriceSection from "../components/sections/PriceSection";
import AboutCertificationSection from "../components/sections/AboutCertificationSection";
import AboutCertificationItem from "../components/elements/AboutCertificationItem";

const indexPage = () => (
  <div className="bg-white">
    <PageTitle pageTitle={"Home"} description={``} />
    <AboutUsSection
      title="Custom POS solutions"
      description={`We develop software for POS terminals, cash registers, and other payment processing devices. We build custom integrations with payment processors, inventory systems, or e-commerce platforms`}
    />
    <ServiceSection>
      <ServiceItem
        name="Fast"
        description={`We develop solutions with minimal hardware requirements to speed up installation and deployment. Our software systems that can handle a high volume of transactions without slowing down.`}
        Icon={PhoneIcon}
        buttonText="Contact us"
        buttonPath="/#contacts"
      />
      <ServiceItem
        name="Reliable"
        description={`We offer fast and reliable online and offline functionality with data backup and recovery options, so you can retrieve your critical data in case of system failures.`}
        Icon={SupportIcon}
        buttonText="Contact us"
        buttonPath="/#contacts"
      />
      <ServiceItem
        name="Secure"
        description={`We develop solutions that use end-to-end encryption protocols to safeguard transaction data with a firewall and anti-malware protection that can detect and prevent cyber threats`}
        Icon={NewspaperIcon}
        buttonText="Contact us"
        buttonPath="/#contacts"
      />
    </ServiceSection>
    <StatisticSection
      title="Key to smarter transactions"
      description={`By implementing our POS development solutions, you can streamline your business operations, reduce transaction times, improve security, and boost sales.`}
    >
      <StatisticItem coloredText={`>40`} title="Clients" />
      <StatisticItem coloredText={`>3000`} title="Point of Sales" />
      <StatisticItem coloredText={`99,9%`} title="SLA" />
    </StatisticSection>
    <AboutCertificationSection title="Certification Project" description="Posexpert proceeds certification process for our new products and products we develop for our customers" showMoreText="Know more about certification" showMorePath="/certification">
      <AboutCertificationItem
        title="Global tasks of the certification project:"
        description=""
        imageName="pos_pos.jpg"
        features={[
          { title: "Documentation and Requirements Analysis", description: "We thoroughly understand the updated legislation on control systems", icon: ScaleIcon },
          { title: "Design", description: "We prepare the design and develop solutions for the information system to meet legislative requirements", icon: DocumentSearchIcon },
          { title: "Implementation and Testing", description: "We implement the code, prepare and conduct system testing", icon: CodeIcon },
          { title: "Documentation", description: "We prepare a document package for product certification", icon: ClipboardCheckIcon },
        ]}
      />
    </AboutCertificationSection>
    <PriceSection
      title="Base rate starting from"
      price="€35"
      currency="per hour"
      // linkText="Learn about our membership policy"
      // linkPath="#"
      buttonText="Contact us"
      buttonPath="/#contacts"
    />
    <PortfolioSection
      title="Our Portfolio"
      showMoreText="Show more projects"
      showMorePath="/portfolio/"
      issuesId="9048,9046,9045"
    />
    <ContactSection
      title={"Contact with us"}
      description={`Get in touch with us to discuss our POS development solutions`}
      email={"ask@posexpert.se"}
      phone={"+46 705 14 80 55"}
      firstNameLabel={"Your name"}
      emailLabel={"Email"}
      phoneLabel={"Phone number"}
      messageLabel={"Message"}
      submitButton={"Submit"}
      submitButtonSuccess={"Sent"}
      subjectMail={"Request from Contacts"}
    />
    <Footer />
  </div>
);

export default indexPage;
