import React from "react";
import { Link } from "gatsby";

const ServiceItem = ({ name, description, Icon, buttonText, buttonPath }) => (
  <div key={name} className="flex flex-col bg-white rounded-2xl shadow-xl">
    <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
      <div className="absolute top-0 p-5 inline-block bg-teal-600 rounded-xl shadow-lg transform -translate-y-1/2">
        <Icon className="h-6 w-6 text-white" aria-hidden="true" />
      </div>
      <h3 className="text-xl font-medium text-stone-900">{name}</h3>
      <p className="mt-4 text-base text-stone-500">{description}</p>
    </div>
    <div className="p-6 bg-stone-50 rounded-bl-2xl rounded-br-2xl md:px-8">
      <Link
        to={buttonPath}
        className="text-base font-medium text-teal-700 hover:text-teal-600"
      >
        {buttonText}
        <span aria-hidden="true"> &rarr;</span>
      </Link>
    </div>
  </div>
);

export default ServiceItem;
