import React from "react";

const ServiceSection = ({ children }) => (
  <section
    className="-mt-32 bg-stone-800 relative z-10 px-4 pb-12 sm:px-6 lg:px-8"
    aria-labelledby="about-us"
  >
    <h2 className="sr-only" id="about-us">
      About us
    </h2>
    <div className="grid grid-cols-1 gap-y-20 max-w-7xl mx-auto lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
      {children}
    </div>
  </section>
);

export default ServiceSection;
