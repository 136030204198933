import React from "react";

const StatisticSection = ({ title, description, children }) => (
  <div className="bg-gray-50 pt-12 sm:pt-16">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto text-center">
        <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
          {title}
        </h2>
        <p className="mt-3 text-xl text-gray-500 sm:mt-4">{description}</p>
      </div>
    </div>
    <div className="mt-10 pb-12 bg-gray-50 sm:pb-16">
      <div className="relative">
        <div className="absolute inset-0 h-1/2 bg-gray-50" />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto">
            <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
              {children}
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default StatisticSection;
