import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Header from "./Header";

const imgPath =
  "https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&&sat=-100";

const AboutUsSection = ({ title, description }) => (
  <div className="bg-white">
    {/* Header */}
    <div className="relative pb-32 bg-stone-800">
      <Header title />
      <div className="absolute inset-0">
        <StaticImage
          src={imgPath}
          alt="POSExpert"
          className="w-full h-full object-cover"
          imgClassName="w-full h-full object-cover"
          placeholder="none"
        />
        <div
          className="absolute inset-0 bg-stone-800 mix-blend-multiply"
          aria-hidden="true"
        />
      </div>
      <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl">
          {title}
        </h1>
        <p className="mt-6 max-w-3xl text-xl text-stone-300">{description}</p>
      </div>
    </div>
  </div>
);

export default AboutUsSection;
