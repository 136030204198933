import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useImageForData } from "../../hooks/useAllImages";

const AboutCertificationItem = ({
  title,
  description,
  features,
  imageName,
}) => {
  const img = useImageForData(imageName);
  return (
    <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
      <div className="relative">
        <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
          {title}
        </h3>
        {description && (
          <p className="mt-3 text-lg text-gray-500">{description}</p>
        )}

        <dl className="mt-10 space-y-10">
          {features.map((item) => (
            <div key={item.title} className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 bg-gray-800 rounded-xl shadow-lg text-white">
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                  {item.title}
                </p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">
                {item.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>

      {imageName && (
        <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
          <GatsbyImage
            image={img}
            className="rounded-lg shadow-lg object-cover object-center w-full max-h-80 h-full"
            alt={title}
          />
          {/* <img className="relative mx-auto" src={imageName} alt="" /> */}
        </div>
      )}
    </div>
  );
};

export default AboutCertificationItem;
